import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './Components/App/App'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TagManager from 'react-gtm-module'
import Lottie from "lottie-react";
import gifJson from "./assets/images/loading.json";

const Detalle = React.lazy(() => import( './Components/Home/Buy/Detail/Detalle'));
const Comprar = React.lazy(() => import( './Components/Home/Buy/Detail/Comprar'));
const Vender = React.lazy(() => import( './Components/Home/Sale/Vender'));
const Respuesta = React.lazy(() => import( './Components/Home/Buy/Detail/Respuesta'));
const Sale = React.lazy(() => import( './Components/Sale/Sale'));
const Index = React.lazy(() => import( './Components/Comparar/Index'));
const Unsubscribe = React.lazy(() => import( './Components/Unsubscribe/Unsubscribe'));
const Error = React.lazy(() => import( './Components/Error/Error'));
const Client = React.lazy(() => import( './Components/Clients/Index'));
const Listado = React.lazy(() => import('./Components/Listado/Listado'));
const Shopify = React.lazy(() => import('./Components/Shopify/Shopify'));
const ShopDetalle = React.lazy(() => import('./Components/Shopify/ShopDetalle'));
const Cookies = React.lazy(() => import('./Components/Pages/Cookies'));
const Terminos = React.lazy(() => import('./Components/Pages/Terminos'));
const Politicas = React.lazy(() => import('./Components/Pages/Politicas'));
const DetalleCar = React.lazy(() => import('./Components/Carro/Detalle'));
const Cookie = React.lazy(() => import('./Components/CookieBar'));
const Videovigilancia = React.lazy(() => import('./Components/Pages/Videovigilancia'));
const Estado = React.lazy(() => import('./Components/Estado'));
const tagManagerArgs = {
    gtmId: 'AW-11015264208'
}

TagManager.initialize(tagManagerArgs)

const router = createBrowserRouter([
  {
    path: "*",
    element: <Error />
  },
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/ventas/:id",
    element: <Sale/>,
  },
  // {
  //   path: "/comprar/carro/detalle/:id",
  //   element: <Detalle/>,
  // },
  {
    path: "/comprar/",
    element: <Comprar />,
  },
  {
    path: "/vender/",
    element: <Vender />,
  },
  {
    path: "/respuesta/",
    element: <Respuesta />,
  },
  {
    path: "/comparar/",
    element: <Index />
  },
  {
    path: "/unsuscribe/:email",
    element: <Unsubscribe />
  },
  {
    path: "/registro-clientes/",
    element: <Client />
  },
  {
    path: "/carros-listado",
    element: <Listado />
  },
  {
    path: "/triplo-shop",
    element: <Shopify />
  },
  {
    path: "/triplo-shop/product/:id",
    element: <ShopDetalle />
  },
  {
    path: "/politica-cookies/",
    element: <Cookies />
  },
  {
    path: "/terminos-condiciones/",
    element: <Terminos />
  },
  {
    path: "/tratamiento-datos/",
    element: <Politicas />
  },
  {
    path: "/comprar/carro/detalle/:id",
    element: <DetalleCar />
  },
  {
    path: "/cookie",
    element: <Cookie />
  },
  {
    path: "/politica-videovigilancia/",
    element: <Videovigilancia />
  },
  {
    path: "/estado/",
    element: <Estado />,
  },
]);

const loading = (
  <div className="pt-3 text-center">
    <Lottie animationData={gifJson} loop={true} className="loadLottie" /> 
    <p>Cargando información...</p>  
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={loading}>
      <RouterProvider router={router} />
    </Suspense>    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
